import React, { Component } from 'react'
import { connect } from 'react-redux'
// import store from '../../redux/store';
// import { initTimer } from '../../redux/masterClock/masterClock.actions'
// import Voice from '../../redux/granular/granular-objects/Voice'
// import { getFrame } from '../../utils'

// import { getRandom } from '../../utils'
// import { getWaveformPoints,  drawCanvasLinePath } from '../../utils'
// import { updateMousePos, storeMouseRef } from '../../redux/mouse/mouse.actions'
// import soundsInfo from '../sounds-info/SoundsInfo'

// import NamesList from '../../components/NamesList/NamesList'
import Canvas from '../../components/canvas-use/Canvas'
// import ControlsModal from '../../components/controls-modal/ControlsModal'

import './WaveFarming.css'
// import globalSettings from '../../globalSettings'
// const { baseMutationValue } = globalSettings


class WaveFarming extends Component {

    state = {
        // started : false,
        // count : 0,
    }

    componentDidMount() {
        


      }

      //draw loop -- gets called from window.requestAnimation in Canvas
      draw = (ctx, frameCount) => {
        // const { count } = this.state;
       const { voices, loaded } = this.props;      

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        //if soundfile data is loaded then we should have voices
        // voices are created in AudioEngine (for now)
        if(loaded){
            if(voices.length > 0 ){
                voices.forEach((voice, i) => {
                   if(voice.active){
                    //    voice.updatePaused(pausedVoices);
                        // console.log(voice.grains)
                       if(!voice.paused || voice.waiting){
                        //    voice.update(voiceSettings.filter(item => item.id === i)[0])   
                           voice.render(ctx)
                       }                
                   }       
               })
           }
           
        }
      }



    render(){
       
        // const { initTimer, loaded, timerStarted, playingSoundsList, soundsInfo, controlsOpen, voices, moreAboutOpen } = this.props;
        // const { controlsOpen, voices} = this.props;
        // console.log(playingSoundsList, soundsInfo)
        // console.log(moreAboutOpen);
        return (
            <div style={{maxHeight : '90vh', overflow: 'hidden'}}>


                <React.Fragment>
                    {/* <NamesList playingSoundsList={playingSoundsList}/> */}
                    <Canvas className="wave-canvas" draw={this.draw} width={window.innerWidth} height={window.innerHeight}/>
                </React.Fragment>

{/* 
                {
                    controlsOpen && 
                        <ControlsModal controlsOpen={controlsOpen} voices={voices}/>
                } */}

            </div>
           
        )
    }  
}



const mapStateToProps = state => ({
    controlsOpen : state.controls.controlsOpen,
    loaded : state.granular.loaded,
    voices : state.granular.voices,
    // timerStarted : state.granular.timerStarted,
    // playingSoundsList : state.soundsInfo.playingSoundsList,
    // pausedVoices : state.controls.pausedVoices,
    // soundsInfo : state.soundsInfo.soundsInfoArray,
    // soundFileData : state.granular.soundFileDatas,
    // voiceSettings : state.controls.voiceSettings,
    // masterSettings : state.controls.masterSettings,
  })
  const mapDispatchToProps = dispatch => ({
    // addSoundFileDataToRedux : () => dispatch(addSoundFileDataToRedux()),
    // updateMousePos : (x, y) => dispatch(updateMousePos(x, y)),
    // storeMouseRef : (mousePos) => dispatch(storeMouseRef(mousePos)),
    // initTimer : () => dispatch(initTimer()),
    // updateVoice : (idx, voice) =>  dispatch(updateVoice(idx, voice)),
    // addItemToPlayingSoundsList : (item) => dispatch(addItemToPlayingSoundsList(item))
    // resetSoundCircleControlStates : () => dispatch(resetSoundCircleControlStates()),
})


export default connect(mapStateToProps, mapDispatchToProps)(WaveFarming)