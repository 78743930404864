import React from 'react';
import './Slider.css';

const Slider = ({name, updateParent, value, minVal, maxVal, step}) => {
    return (
        <div className="slider-container">
        <label htmlFor={name} className="label">
            {name} <span className="white">({ value })</span>
        </label>
        <input
            className="slider-thing"
            name={name}
            type="range"
            min={minVal}
            max={maxVal}
            step={step}
            value={value}
            onChange={(e) => updateParent(name, e.target.value)}
        />
    </div>
    )
}
export default Slider