import React from 'react'
import { Link } from 'react-router-dom'
import LoginForm from '../../components/login-form/LoginForm'
import './UploadLogin.css'
const UploadLogin = () => {
    return ( 
        <div className="wrapper">
            <div className="login-container">
                <div className="upload-text">
                    <p className="message">you'll have to make an account to upload a sound</p>
                    {/* <p className="white message">to upload a recording, you'll have to sign in, but I won't send you any email unless you explicitly tell me to by clicking the button below</p>
                    <button className="back-button" onClick={() => toggleEmail(!email)}>send me an email when you make something else cool like this</button>
                    { email ? 
                        <p>great! I'll let you know when I publish my next fun audio adventure</p>
                        :
                        null    
                    } */}
                   
                </div>

                <div className="login-form-con">
                    <LoginForm />
                   
                </div>   
                <div className="back-button-container">
                    <p className="white"> <Link to="/" className="back-button"> cancel</Link></p>
                </div> 
                
            </div> 
        </div>
     );
}
 
export default UploadLogin;