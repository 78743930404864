import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toggleControlsOpen } from '../../redux/controls/controls.actions'
import { updateMasterAudioSettings } from '../../redux/controls/controls.actions'
import ItemControls from '../item-controls/ItemControls'
import Slider from '../slider/Slider'
import AddSound from '../add-sound/AddSound'
import './ControlsModal.css'
// const testItems = Array.from({ length: 12}, () => {
//     return {
//         volume: 1.0,
//         rate: 1.0,
//         attack: Math.random(),
//         release: Math.random(),
//     }
// })

const config = {
    items : [
        {
            name: 'volume',
            minVal: 0.01,
            maxVal: 2.0,
            step: 0.01,
        },
        {
            name: 'rate',
            minVal: 0.01,
            maxVal: 2.0,
            step: 0.01,
        },
        {
            name: 'mutation',
            minVal: 1,
            maxVal: 1000,
            step: 1,
        }
    ]
}



class ControlsModal extends Component {
    
    state = {
        settings: null,
        pausedVoices : [],
    }


    componentDidMount(){
        const { settings } = this.props
        // console.log(masterSettings);
        this.setState({ settings: {
            volume: settings.volume,
            rate: settings.rate,
            mutation : settings.mutation, 
            }
        });
    }


    update = (prop, value) => {
        const { settings } = this.state;
        const { updateMasterAudioSettings } = this.props;
        const newSettings = {...settings, [prop] : Number(value)};
        this.setState({ settings: newSettings });
        updateMasterAudioSettings(newSettings);
    }

    render(){
        const { settings } = this.state;
        const { controlsOpen, toggleControlsOpen, voices, voiceSettings, soundFileDatas } = this.props;
        // console.log(pausedVoices);
        let sortedVoices
        if(voices.length > 0){
             sortedVoices = voices.sort((a, b) => a.id > b.id ? 1 : -1);
        }
        let sortedVoiceSettings
        if(voiceSettings.length > 0){
            sortedVoiceSettings = voiceSettings.sort((a, b) => a.id > b.id ? 1 : -1);
       }
       console.log(sortedVoiceSettings);

        return (
            <div className="controls-flex-wrapper">
                <div className={`control-modal ${controlsOpen ? 'open' : ''}`}>
                    <div className="close" onClick={toggleControlsOpen}>
                        <p className="whiteP"> x</p>
                    </div>
                    <div className="all-slider-container">
                        
                        <div className="master-controls-container">
                            <h2 className="master-controls-header">all voices</h2>

                            {   settings === undefined || !settings ?
                                <div></div>
                                :
                                config.items.map( item => 
                                        <div className="slider-item" key={`master-control-${item.name}`}>
                                                <Slider 
                                                    name={item.name} 
                                                    value={settings[item.name]} 
                                                    updateParent={this.update} 
                                                    minVal={item.minval} 
                                                    maxVal={item.maxVal} 
                                                    step={item.step}
                                                    />
                                        </div>
                                    )
                            }
                        </div>
 
                        <div className="item-controls-container">
                        {
                            sortedVoices.length > 0 ? 
                                sortedVoices.map((voice, i) => <ItemControls key={`voice-controls-voice-${i}`} id={sortedVoiceSettings[i].id} voiceSettings={sortedVoiceSettings[i]}/> )
                                :
                                <div>no currently active items </div>
                        }
                            
                                <AddSound voices={voices} soundFileDatas={soundFileDatas}/>
                            
                        </div>



                    </div>

                </div>
            </div>
    
        )
    }
    
}

const mapStateToProps = state => ({
    controlsOpen : state.controls.controlsOpen,
    settings : state.controls.masterSettings,
    voiceSettings : state.controls.voiceSettings,
    pausedVoices : state.controls.pausedVoices,
    soundFileDatas : state.granular.soundFileDatas,
})

const mapDispatchToProps = dispatch => ({
    toggleControlsOpen : () => dispatch(toggleControlsOpen()),
    updateMasterAudioSettings : (newSettings) => dispatch(updateMasterAudioSettings(newSettings)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ControlsModal)