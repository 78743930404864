import React from 'react'
import './Pause-Icon.css'
const PauseIcon = () => {
    return ( 
        <svg className="pause-icon" viewBox="0 0 40 35">
            <rect 
                className="pause-rect"
                // x={x - size/2} 
                // y={y - size/2} 
                // width={size * 0.4} 
                // height={size } 
                x={`${5}`}
                y={`${5}`}
                width={`${10}`}
                height={`${25}`}
                fill="#000000"
                />
            <rect 
                className="pause-rect"
                x={`${25}`} 
                y={`${5}`} 
                width={`${10}`} 
                height={`${25}`} 
                fill="#000000"
                />
        </svg>
     );
}
 
export default PauseIcon;