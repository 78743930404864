import { UploadActionTypes } from  './upload.actions.types'

const INITIAL_STATE = {
    uploadPageOpen : false,
}

export const uploadReducer = (state= INITIAL_STATE, action) => {
    switch(action.type){
        case UploadActionTypes.TOGGLE_UPLOAD_PAGE_OPEN :
            console.log('upload reducer', action.type)
            return {
                ...state,
                uploadPageOpen : !state.uploadPageOpen
            }

        default : 
            return {
                ...state,
            }    
    }
}