import React from 'react'
import './NamesList.css'

const NamesList = ({playingSoundsList}) => {
    // console.log(playingSoundsList);
    return ( 
        <div className="names-container">
            {
               playingSoundsList &&
                playingSoundsList.map((item, i) =>  
                    <div 
                        key={`sound-info-${i}`}
                        className="sound-info-container"
                        style={{
                            // top: `${(i + 2) * 100}px`, 
                            color: `rgba(220, 200, 220, ${0.4/(i+1)})`,
                            borderBottom: `1px solid rgba(220, 200, 220, ${0.3/(i+1)})`, 
                        }}
                    >

                        <p 
                            key={i} 
                            className="sound-title"
                            style={{
                                    // top: `${(i + 2) * 100}px`, 
                                    color: `rgba(220, 200, 220, ${0.4/(i+1)})`,
                                
                                }}
                            >
                                {`${item.description}, ${item.location}, ${item.date}, uploaded by ${item.user}`}
                                
                        </p>
                    </div>
                )
            } 
                        {/* {
            soundsInfo.map((item, i) =>  
                <p 
                    key={i} 
                    className="sound-title"
                    style={{top: `${(i + 20) *40}px`}}
                    >
                        {item.description}
                        
                </p>)
            }  */}
        </div>
     );
}
 
export default NamesList;