import React, { useEffect, useRef } from 'react'
import cloud from '../../assets/mario-cloud.png'
import './DropZone.css'




const DropZone = ({updateParent, children}) => {

    const dropRef = useRef(null)

    useEffect(() => {
        const drop = dropRef.current
        window.addEventListener('dragover', (e) => {
            e.preventDefault();
        })
        
        window.addEventListener('drop', (e) => {
            e.preventDefault();
        })
        drop.addEventListener('drop', (e) => {
            e.preventDefault()
            updateParent(e)

        })
    },[updateParent])


    return ( 
        <div className="drop-zone" ref={dropRef}>
            <div className="upload-banner">
                <img src={cloud} alt="mario cloud" className="upload-image"/>
                <h3 className="drop-heading">drop an mp3 file here or</h3>        
            </div>
            {children}
        </div>
     );
}
 
export default DropZone;