import { PresetsActionTypes } from './presets.actions.types'
import presets from './presets'

const presetsConfig = [
    {
        name: 'decay',
        preset: presets.decayDrone,
    },
    {
        name: 'percussive',
        preset: presets.percussive,
    },
    {
        name: 'mellow',
        preset: presets.mellow,
    },
    {
        name: 'lively',
        preset: presets.lively,
    }
]



const INITIAL_STATE = {
    currentPresetIdx : 0, 
    presetsConfig: presetsConfig,
}

export const presetsReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case PresetsActionTypes.SET_CURRENT_PRESET_IDX :
            return {
                ...state,
                currentPresetIdx: action.payload.idx,
            }
        default :
            return {
                ...state
            }    
    }
}