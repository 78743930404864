import store from '../store'
import Voice from '../granular/granular-objects/Voice'
// import { getFrame } from '../../utils'
import { updateTickTime } from '../masterClock/masterClock.actions'
import { updateVoice } from '../../redux/granular/granular.actions'
import { addItemToPlayingSoundsList } from '../../redux/sounds-info/soundsInfo.actions'
// import { addVoice, updateVoice } from '../granular/granular.actions'
// import { playGrain } from '../audio-middleware/audio.actions'
// import globalSettings from '../../globalSettings'


class SilenceFarm {
    constructor(){
        // this.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
        // window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
        this.tickTime = 0;
        this.tickStarted = false;
        
    }

    init(){
        const ticker = () => {
            
            this.tickTime = this.tickTime + 1;
            // console.log(this.tickTime);
            store.dispatch(updateTickTime(this.tickTime));
            this.farmSilence();
            // this.animateGears();
            // this.tweakSoundCircles();
            // this.rotateSoundCircles();      
            this.requestAnimation = window.requestAnimationFrame(ticker);
            
        }

        if(!this.tickStarted){
            this.tickStarted = true;
            
                ticker();         
        }

    }


    stop(){
        this.requestAnimation.window.cancelAnimationFrame()
    }    

    farmSilence(){
        const { voices, loaded } = store.getState().granular; 
        const { voiceSettings, pausedVoices } = store.getState().controls;
        // console.log(voiceSettings)
        if(loaded){
            // if(voices.length > 0 && count === 0){
            if(voices.length > 0){
                voices.forEach((voice, i) => {
                    // console.log(voice.id)
                   if(voice.active){
                       voice.updatePaused(pausedVoices);
                        voice.updateAlpha()
                       if(!voice.paused || voice.waiting){
                           
                           voice.update(voiceSettings.filter(item => item.id === i)[0])   
                        //    voice.render(ctx)
                       }

                       this.addMutation(voice)
                   }       
               })
           }
        }
    }

    addMutation = (voice) => {
        const { currentPresetIdx, presetsConfig } = store.getState().presets
        const { masterSettings } = store.getState().controls
        const preset = presetsConfig[currentPresetIdx].preset
        
        const soundFileData = store.getState().granular.soundFileDatas
        const soundsInfo = store.getState().soundsInfo.soundsInfoArray
        //this value should be controlled by the mutation slider
        //changes the bufnum/waveform/sound
        const coin = Math.random()
        
        // there must be a simple way to invert this for the slider
        const mutationCutoff = 1 - (preset.baseMutationValue * (1/masterSettings.mutation))
       
        if(coin > mutationCutoff){
            
            const bufnum = Math.floor(Math.random() * soundFileData.length)
            // gotta refigure this while system of choosing and syncing
            const inList = store.getState().soundsInfo.playingSoundsList.some(item => item.id === bufnum);
            if(!inList){
                store.dispatch(addItemToPlayingSoundsList(soundsInfo[bufnum]))
            }
            const newData = soundFileData[bufnum];
            const newVoice = new Voice(voice.id, bufnum, newData, voice.paused)
            store.dispatch(updateVoice(voice.id, newVoice))
        } 
    }





}
export default SilenceFarm    



        // loaded : state.granular.loaded,
        // timerStarted : state.granular.timerStarted,
        // controlsOpen : state.controls.controlsOpen,
        // playingSoundsList : state.soundsInfo.playingSoundsList,
        // voices : state.granular.voices,
        // pausedVoices : state.controls.pausedVoices,
        // soundsInfo : state.soundsInfo.soundsInfoArray,
        // soundFileData : state.granular.soundFileDatas,
        // voiceSettings : state.controls.voiceSettings,
        // masterSettings : state.controls.masterSettings,

        // console.log(pausedVoices)