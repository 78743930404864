import React, { Component } from 'react';
import { connect } from 'react-redux'
import { initSetting } from '../../utils'
import IntroPage from '../../pages/intro-page/IntroPage'
import { initTimer } from '../../redux/masterClock/masterClock.actions'
import { setCurrentPresetIdx } from '../../redux/presets/presets.actions'
import { updateCurrentControlSetting, updatePaused, updateVoiceAudioSettings } from '../../redux/controls/controls.actions'
import TreeFarming from '../../themes/tree-farming/TreeFarming'
import TextFarming from '../../themes/text-farming/TextFarming'
import PlotFarming from '../../themes/plot-farming/PlotFarming'
import WaveFarming from '../../themes/wave-farming/WaveFarming'
import ThemeSelectionBar from '../../components/theme-selection-bar/ThemeSelectionBar'
import PresetSelectionBar from '../../components/preset-selection-bar/PresetSelectionBar'
import ControlsModal from '../../components/controls-modal/ControlsModal'
import './MainThemed.css'


const themeConfig = [
    {
        name: 'trees',
        component: TreeFarming
    },
    {
        name: 'text',
        component: TextFarming,
    },
    {
        name: 'plots',
        component: PlotFarming,
    },
    {
        name: 'wave',
        component: WaveFarming,
    }
]


class MainThemed extends Component {
    state = {  
        themes: themeConfig,
        currentThemeIdx: 3,
        presetsConfig: null,
        currentPresetIdx: 0,
    }


    componentDidMount(){
        const { presetsConfig, currentPresetIdx } = this.props
        this.setState({ presetsConfig, currentPresetIdx })
        const preset = presetsConfig[currentPresetIdx].preset;
        updateCurrentControlSetting(initSetting(preset))
    }

    togglePreset = val => {
        const { presetsConfig } = this.state;
        // const { setCurrentPresetIdx, updateCurrentControlSetting, voices, updatePaused, updateVoiceAudioSettings } = this.props
        const { setCurrentPresetIdx, updateCurrentControlSetting, voices } = this.props
        this.setState({ currentPresetIdx : val })
        setCurrentPresetIdx(val)
        const preset = presetsConfig[val].preset;
        updateCurrentControlSetting(preset)
        voices.forEach( voice => {
            // updatePaused()
            // updateVoiceAudioSettings()
        })

        
        
        // also set a new master baseMutationvalue, maybe take that out of master settings altogether
    }

    toggleTheme = (val) => {
        // console.log(val);
        this.setState({ currentThemeIdx: val})
    }

    render() { 
        const { currentThemeIdx, themes, currentPresetIdx  } = this.state
        const { timerStarted, loaded, initTimer, controlsOpen, voices, presetsConfig } = this.props
        let Theme = null
      
        if(themes[currentThemeIdx]){
            Theme = themes[currentThemeIdx].component
        }
       
        return ( 
            <div className="main-wrapper">


                {
                    timerStarted ?
                            <div className="theme-container">
                                <div className="selection-container">
                                    <ThemeSelectionBar themes={themes} updateParent={this.toggleTheme} currentThemeIdx={currentThemeIdx}/>
                                    <PresetSelectionBar presets={presetsConfig} updateParent={this.togglePreset} currentPresetIdx={currentPresetIdx}/>
                                </div>
                                {   Theme ? 
                                    <Theme />
                                    :
                                    null
                                }
                                                    {
                                // currentThemeIdx !== 2 &&                                
                                controlsOpen && 
                                    <ControlsModal controlsOpen={controlsOpen} voices={voices}/>
     
                                }
                            </div>
                        :
                            <IntroPage initTimer={initTimer} loaded={loaded}/>    
                }                

                </div>

         );

    }
}
 
// loaded, voices, soundFileData, updateVoice, voiceSettings, masterSettings, pausedVoices, soundsInfo, addItemToPlayingSoundsList


const mapStateToProps = state => ({
    loaded : state.granular.loaded,
    timerStarted : state.granular.timerStarted,
    controlsOpen : state.controls.controlsOpen,
    playingSoundsList : state.soundsInfo.playingSoundsList,
    voices : state.granular.voices,
    pausedVoices : state.controls.pausedVoices,
    soundsInfo : state.soundsInfo.soundsInfoArray,
    soundFileData : state.granular.soundFileDatas,
    voiceSettings : state.controls.voiceSettings,
    masterSettings : state.controls.masterSettings,
    presetsConfig: state.presets.presetsConfig, 
    currentPresetIdx : state.presets.currentPresetIdx,
})

const mapDispatchToProps = dispatch => ({
    initTimer : () => dispatch(initTimer()),
    setCurrentPresetIdx : (idx) => dispatch(setCurrentPresetIdx(idx)),
    updateCurrentControlSetting : (preset) => dispatch(updateCurrentControlSetting(preset)),
    updateVoiceAudioSettings : (id, settings) => dispatch(updateVoiceAudioSettings(id, settings)),
    updatePaused : (idx) => dispatch(updatePaused(idx)),
    // updateVoice : (idx, voice) =>  dispatch(updateVoice(idx, voice)),
    // addItemToPlayingSoundsList : (item) => dispatch(addItemToPlayingSoundsList(item))
})

export default connect(mapStateToProps, mapDispatchToProps)(MainThemed);