import React from 'react'
import Canvas from '../../components/canvas-use/Canvas'
import './Plot.css'
const Plot = ({index, loaded, voice}) => {

    const draw = (ctx) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        if(loaded)
        if(voice.active){
            //    voice.updatePaused(pausedVoices);
               if(!voice.paused || voice.waiting){
                //    voice.update(voiceSettings.filter(item => item.id === i)[0])   
                   voice.render(ctx)
               }                
           } 
    }


    return ( 
        <div className="plot-container">
           <Canvas className="plot-canvas" draw={draw} width={window.innerWidth} height={window.innerHeight/8}/>
           {/* <div className="plot-controls">
               {`controls for voice ${index} here`}
           </div> */}
        </div>
     );
}
 
export default Plot;