const GranularActionTypes = {
    INIT_SOUND_FILE_DATA : 'INIT_SOUND_FILE_DATA',
    LOADED : 'LOADED',
    START_TIMER : 'START_TIMER',
    ADD_VOICE : 'ADD_VOICE',
    ADD_VOICE_AND_SETTINGS : 'ADD_VOICE_AND_SETTINGS',
    ADD_GRAIN : 'ADD_GRAIN',
    UPDATE_VOICE : 'UPDATE_VOICE',
    UPDATE_MASTER_AUDIO_SETTINGS : 'UPDATE_MASTER_AUDIO_SETTINGS',
    UPDATE_VOICE_AUDIO_SETTINGS : 'UPDATE_VOICE_AUDIO_SETTINGS'
}
export default GranularActionTypes