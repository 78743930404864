import React, {useState} from 'react'
import PauseIcon from '../pause-icon/Pause-Icon';
import PlayIcon from '../play-icon/Play-Icon';
import './PauseButton.css'
const PauseButton = ({togglePaused, id, paused}) => {
    // console.log(id);
    const [ hover, toggleHover] = useState(false);

    return ( 
        <button 
            className={`pause-button ${hover ? 'hover-bg' : paused ? 'paused-bg' : ''} `} 
            onClick={() => togglePaused(id)} 
            onMouseEnter={() => toggleHover(!hover)}
            onMouseLeave={() => toggleHover(!hover)}

        >
                {paused ? <PlayIcon /> : <PauseIcon />}
        </button>
     );

    }
export default PauseButton;