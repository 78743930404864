// import CrowdSound from './CrowdSound';
import masterClockActionTypes from './masterClock.actions.types';
import { startTimer } from '../granular/granular.actions';
import SilenceFarm from './SilenceFarm';

const masterClockMiddleware = store => {
    const silenceFarm = new SilenceFarm();

    return next => action => {

        switch (action.type){
           
            case masterClockActionTypes.INIT_TIMER : 
                 silenceFarm.init()
                 store.dispatch(startTimer());
                break

            case masterClockActionTypes.GET_CURRENT_TICK_TIME : 
                // store.dispatch(setTickTime(animation.getTime()));
               break
               
            case masterClockActionTypes.STOP_FARM :
                silenceFarm.stop()
                break   

            default :
                break;
        }
        next(action);
    }
}
export default masterClockMiddleware