import React, { Component } from 'react';
import { connect } from 'react-redux'
import Plot from './Plot'
// import AddPlot from './AddPlot'
class PlotFarming extends Component {
    state = {  }
    render() { 
        const { loaded, voices }  = this.props
        return ( 
            <div className="wrapper-wrapper">
                 {/* <AddPlot /> */}
                <div className="plots-wrapper">
                        {/* <AddPlot />
                        <AddPlot /> */}
                    {
                    voices.map((voice, idx) => 
                        <Plot key={idx} index={idx} loaded={loaded} voice={voice}/>
                    )
                    }
                    
                </div>
           
            </div>
         );
    }
}
 
const mapStateToProps = state => ({
    loaded : state.granular.loaded,
    voices : state.granular.voices,
})

export default connect(mapStateToProps)(PlotFarming);
