import React from 'react'
import { useDispatch } from 'react-redux'
import Voice from '../../redux/granular/granular-objects/Voice'
import { addVoiceAndSettings } from '../../redux/granular/granular.actions'
import './AddSound.css'

const AddSound = ({ voices, soundFileDatas}) => {

   const bufnum = Math.floor(Math.random() * soundFileDatas.length);
//    console.log(bufnum);

   const dispatch = useDispatch();

    return ( 
        <div className="add-sound-container">
            <div className="add-sound-info">
                <p> add sound </p>
            </div>
               
            <button 
                onClick={() => dispatch(addVoiceAndSettings(new Voice(voices.length, bufnum, soundFileDatas[bufnum], false)))}
                className="add-sound-button"
            > 
                + 
            </button>
        </div>
     );
}
 
export default AddSound;


