import React from 'react'
import './AppleMessage.css'
const AppleMessage = () => {
    return ( 
        <div className="wrapper">
            <div className="text-container">
                <p className="text">
                    Sorry!  It looks like you're using an IOS device, or Safari.  Which means you're not really using the web up to it's potential!
    
                </p>
                <p>
                    Apple's been making it really hard to ensure decent audio experiences on the web on ios, because they want people to make apps for their store.
                </p>
                <p>
                    Slowly, over the last ten or fifteen years, they really have become the new old Microsoft, just worse.
                </p>
                <p>
                    If this wasn't a project that I'm just doing for fun I might try to jump through their hoops, but this is a passion project and so I just decided, I'd rather make this nicer for all the other devices out there,
                    and just sort of take a stand and ban crappy apple software from my free, fun audio projects.
                </p>
                <p style={{fontSize: '2rem'}}>
                😬
                </p>
                <p>
                    If you're interested, come back and try this on Chrome or Firefox or duckduckgo or Opera or whatever, on any kind of device that isn't IOS, it runs fine on all computers (even macs, using Chrome) or on android phones or tablets. 
                </p>
                {/* <p>
                    (and, please, can we all just stop buying apple products?  linux is so much better, for a fraction of the cost, than apple these days....✌️)
                </p> */}
            </div>
        </div>
     );
}
 
export default AppleMessage