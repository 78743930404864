import React from 'react'
import './ThemeSelectionBar.css'

const selectedGreens = ['poetic-green', 'textual-green', 'hand-green', 'original-green'];

const ThemeSelectionBar = ({themes, currentThemeIdx, updateParent}) => {
    return ( 
        <div className="theme-controls-container">        
        {
            themes.map((theme, i) => 
                <button 
                    key={`theme-toggle-${themes[i].name}`} 
                    className={`theme-button ${i === currentThemeIdx ? selectedGreens[i] : 'deselected-color'}`} 
                    onClick={() => updateParent(i)}
                >
                    {theme.name}
                </button>
                )
        }
        </div>
     );
}
 
export default ThemeSelectionBar;