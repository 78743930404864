import React from 'react'
import IntroText from './IntroText'
import StartButton from '../../components/start-button/StartButton'
import AppleMessage from './AppleMessage'
import './IntroPage.css'



const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
if (isIOS) {
  console.log('This is a IOS device');
} else {
  console.log('This is Not a IOS device');
}

const isSafari =  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// let isSafari
// const ua = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
// if (ua.indexOf('chrome') > -1) {
//     isSafari = false
//   } else {
//     alert("2") // Safari
//   }
// const isIOS = true;


const IntroPage = ({initTimer, loaded}) => {
    return ( 
        <div className="intro-screen-container">
            {loaded ?
                    <div className="about-container">
                         <StartButton start={initTimer}/>
                         <IntroText />
                       
                    </div>
                       
                  
               :

               isIOS || isSafari ? 
                // <div> it looks like you're using an ios device, which means that this (and a lot of interesting web stuff) isn't going to work.
                //     sorry!  apple really is the new microsoft these days.  try the site on a computer, if you have one!
                // </div>

                <AppleMessage />
                :

               <div style={{color: 'white'}}>...loading...</div>
            //    if you're on an apple-made mobile device and this
            //    message persists, it's just because apple doesn't want people to use the web, they want people
            //    to use the app store.  $$$.  sorry!  it works on a computer, or anything non-IOS. 
            }
         
        </div>
     );
}
 
export default IntroPage;