import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { addItemsToSoundsInfoArray } from './redux/sounds-info/soundsInfo.actions'
import soundsInfo from './redux/sounds-info/SoundsInfo'
// import Main from './Main'
import MainThemed from './pages/main-themed/MainThemed'
import Header from './components/header/header'
import About from './pages/about/About'
import Upload from './pages/upload-page/UploadPage'
// import Controls from './components/controls/controls'
// import Canvas from './components/canvas/canvas'
// import CanvasClass from './components/canvas-class/Canvas-Class'
// import Canvasp5hook from './components/canvasp5hook/canvasp5hook'

class App extends React.Component{


  componentDidMount(){
    const { addItemsToSoundsInfoArray } = this.props;
    console.log(soundsInfo);
    addItemsToSoundsInfoArray(soundsInfo);
  }



  render(){
    const { timerStarted, uploadPageOpen } = this.props;
    return(
      <BrowserRouter>
        <React.Fragment>
          <Header started={timerStarted}  uploadPageOpen={uploadPageOpen}/>
          <Switch>
            {/* <Route exact path="/" render={()=> <Redirect to='/canvas' />} /> */}
            <Route exact path="/" component={MainThemed} />
            <Route path="/upload" component={Upload} />
            <Route path="/about" component={About} />
            {/* <Route path="/controls" component={Controls} /> */}
            {/* <Route path="/canvas" component={CanvasClass} /> */}
            {/* <Route path="/canvas" component={CanvasClass} /> */}
          </Switch>
          
        </React.Fragment>
      </BrowserRouter>
    )
  }
}

const mapStateToProps = state => ({
  timerStarted : state.granular.timerStarted,
  uploadPageOpen : state.upload.uploadPageOpen,
})

const mapDispatchToProps = dispatch => ({
  addItemsToSoundsInfoArray : (items) => dispatch(addItemsToSoundsInfoArray(items)),
})



export default connect(mapStateToProps, mapDispatchToProps)(App)