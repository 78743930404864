import React, { Component } from 'react'
import { connect } from 'react-redux'
import firebase from '../../firebase/firebase'
import { addItemToFirestore } from '../../firebase/firebase-utils'
import { v4 as uuidv4 } from 'uuid';
import { drawWaveform } from '../../waveform-utils'
import { validateSubmission } from '../../utils'
import { addNewItemToSoundsInfoArray } from '../../redux/sounds-info/soundsInfo.actions'
import TextField from '../../components/TextField/TextField'
import cloud from '../../assets/mario-cloud.png'
import UploadFileModal from '../../components/upload-file-modal/UploadFileModal'
import UploadSuccess from '../../pages/upload-success/UploadSuccess'
import Canvas from '../../components/canvas-use/Canvas'
// import MetaSound from '../../redux/sounds-info/MetaSound'
import './UploadSound.css'

class UploadSound extends Component {
    state = { 
        user: this.props.currentUser,
        storageref: firebase.storage().ref(),
        selectingFile : false,
        fileAttachedImage : cloud,
        fileAttached :  false,
        description: '',
        location: '',
        errors : [],
        modal: true,
        form: false,
        uploadState : '',
        uploadTask : null,
        soundURL: null,
        submission: null,
        waveformData: null,
        success: false,

    }


    componentDidMount(){

        const innerWidth = window.innerWidth
        const canvasWidth = innerWidth < 600 ? 280 : 500
        const canvasHeight = 200
        this.setState({canvasWidth, canvasHeight})
    }


    openModal = () => this.setState({ modal: true, selectingFile: true, fileFailed: false })
     closeModal = () => this.setState({ modal : false, selectingFile: false })


     handleChange = event => {
        this.setState({ [event.target.name]: event.target.value}); 
    }

    createSubmission = () => {
        const {user, location, description, soundURL, soundFileID, waveformDataURL } = this.state;
        
        // id, user, timestamp, soundURL, description, userLocation 
        
        const timestamp = new Date().toString()

        const submission = {
            soundFileID, 
            user, 
            timestamp, 
            soundURL, 
            description, 
            location,
            waveformDataURL,
            filefailed : false,
        }
        
        const invalidSubmission = validateSubmission(submission.description, submission.location);
       
        if(Object.keys(invalidSubmission).length < 1){
            this.setState({submission}, () => {
                this.uploadSubmission()
            })
        } else {
            this.setState({ errors: invalidSubmission })
        }


    }

    uploadSubmission = () => {
        const { submission } = this.state
        const { addNewItemToSoundsInfoArray } = this.props
        if(submission){
           
            this.setState({ loading : true }, () => {
                addItemToFirestore('sounds', submission)
                .then(() => {
                    this.setState({ success: true, loading : false, description: '', soundURL: null}, () => {
                        addNewItemToSoundsInfoArray(submission)
                    })     
                })
                .catch( err => {
                    console.error(err)
                    this.setState({ loading: false, errors: this.state.errors.concat(err)})
                })
            })

        }
    }

    drawWaveform = (ctx) => {
        const { waveformData, mic } = this.state
      
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        ctx.fillStyle = "#32130b";
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
       
        drawWaveform(ctx, waveformData, 'rgba(16, 206, 22, 0.4)', mic)
    }


    isFormValid = () => {
        return true
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if(this.isFormValid()){
            this.setState({errors: [], submitting: true })
            this.createSubmission();
        }
    }

    uploadWaveFromData = (waveFormFilePath, waveformData) => {
        const { storageref } = this.state
       
        const uploadTask = storageref.child(waveFormFilePath).put(waveformData);
        uploadTask.on('state_changed', () => {
                //progress bar here
        }, 
        (err) => {
            console.error(err, 'in upload waveform data')
            this.setState({ 
                errors: this.state.errors.concat(err),
                uploadState: 'error',
            })
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then( downloadURL => {
               
                this.setState({ waveformDataURL: downloadURL })
                    })
        })
    }

     uploadFile = (file, metadata, waveformData, mic) => {
        const {storageref} = this.state;
        if(file){
            const soundFileID = uuidv4();
            const soundFilePath = `sounds/${soundFileID}.mp3`
            const waveFormFilePath = `waveforms/${soundFileID}`
            this.uploadWaveFromData(waveFormFilePath, waveformData)
            
            this.setState({ 
                uploadState: 'uploading',
                file,
                soundFileID,
                waveformData,
                mic,
            }, () => {
                const uploadTask = storageref.child(soundFilePath).put(file, metadata);
                uploadTask.on('state_changed', snap => {
                    const percentUploaded = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
                    this.setState({ percentUploaded })
                }, (err) => {
                    console.error(err)
                    console.log('state changed catch');
                    this.setState({
                        errors: this.state.errors.concat(err),
                        uploadState: 'error',
                        uploadTask: null,
                    })
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then( downloadURL => {
                        this.setState({ soundURL: downloadURL, fileAttached: true, form: true, selectingFile: false, uploadState: ''})
                    }).catch((err) => {
                        console.error(err)
                        console.log('in get download url');
                        this.setState({
                            errors: this.state.errors.concat(err),
                            uploadState: 'error',
                            uploadTask: null      
                        })
                    })
                })
            })
        } else {
            this.setState({ fileFailed : true })
        }

        
     }



    render() { 
        const { errors, description, location, modal, fileAttached, form, file, success, uploadState, fileFailed, canvasWidth, canvasHeight } = this.state
        const { updateParent, upload, audioContext} = this.props
        console.log(uploadState);
      
        return ( 
                <React.Fragment>

                


                { success ? 
                    <UploadSuccess />
                    :
                    <div className="upload-sound-container">
                        {
                            fileFailed ? 
                                <div className='fail-container'>
                                    <p>it seems as though that file wasn't silent enough our purposes.  try again?</p>
                                </div>
                                :
                                <React.Fragment></React.Fragment>

                        }
                    {
                        uploadState === 'error' ?
                            <div>
                                <p>{errors}</p>
                            </div>
                            :
                            null
                    }
                    
                  
                    <div className={modal ? null : "upload-file-container" }>
                        {
                                                        uploadState === 'uploading' ? 

                                                        <div className="in-process">
                                                            hold on a sec while we check your file out...
                                                        </div>
                                                        :
                                                        uploadState === 'error' ?
                                                        <div className="error">
                                                            sorry, there was a problem with your file.
                                                            try again, what we need are files that have a pretty
                                                            consistent loudness.
                                                        </div>
                                                        : 
                                                        <div>
                                                            
                                                        </div>
                        }



                    {
                        fileAttached ?
                        <div>
                           <div className="file-to-upload-container">
                           {/* <img src={fileAttachedImage} alt="a waveform" className="attached-image"/> */}
                           <Canvas draw={this.drawWaveform} width={canvasWidth} height={canvasHeight} className="waveform-canvas"/>
                           <p className="file-description">{file.name}</p>
                           <p>that file looks good, please add a description and location: </p>
                           </div>
                           <div className="form-container">
                                {/* <form onSubmit={this.handleSubmit}> */}
                                <form >
                                    <TextField 
                                        id="file-description"
                                        type="text"
                                        required
                                        name="description"
                                        placeholder="add a description"
                                        onChange={this.handleChange}
                                        value={description} 
                                        error={errors.description}
                                    />
                                    <TextField 
                                        id="user-location"
                                        type="text"
                                        required
                                        name="location"
                                        placeholder="add a location"
                                        onChange={this.handleChange}
                                        value={location} 
                                        error={errors.location}
                                    />
                                    <button type="submit" className="submit-button" onClick={this.handleSubmit}>Submit</button>
                                </form>
                            </div>   
     
                        </div>
     
                            :
                        <div></div>
                    }
     
    
                    </div>
    
                    <UploadFileModal
                        modal={modal}  
                        uploadFile={this.uploadFile}
                        closeModal={this.closeModal}
                        upload={upload}
                        audioContext={audioContext}
                    />

                    {
                        fileFailed ? 
                        <button className="retry-button big-button" onClick={() => updateParent(false, false)}>
                            back to upload page
                        </button>  
                        :
                        <button className="cancel-button big-button" onClick={() => updateParent(false, false)}>
                            cancel
                        </button>  
                    }
 
                
                
                </div>
                }

            </React.Fragment>
         );
    }
}

const mapStateToProps = state => ({
    audioContext : state.soundsInfo.audioContext,
    soundFileDatas : state.granular.soundFileDatas,
})

const mapDispatchToProps = dispatch => ({
    addNewItemToSoundsInfoArray : (item) => dispatch(addNewItemToSoundsInfoArray(item)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadSound);


// <div>
// <input type="file" accept="audio/*" capture onChange={this.handleInput}/>
// <audio id="player" controls></audio>
// </div>