import React, { Component } from 'react';
import { connect } from 'react-redux'
// import ControlsModal from '../../components/controls-modal/ControlsModal'
import Canvas from '../../components/canvas-use/Canvas'
import './TreeFarming.css'
// const offsets = 100;

const canvasWidth = window.innerWidth
const canvasHeight = window.innerHeight

class TreeFarming extends Component {
    state = {  }

    draw = (ctx, frameCount) => {
        // const { count } = this.state;
       const { voices, loaded } = this.props;      
        // console.log('tree')
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        // console.log(voices);
        //if soundfile data is loaded then we should have voices
        // voices are created in AudioEngine (for now)
        if(loaded){
            if(voices.length > 0){
                
                voices.forEach((voice, i) => {
                  
                   if(voice.active){
                    //    voice.updatePaused(pausedVoices);
                       if(!voice.paused || voice.waiting){
                        //    voice.update(voiceSettings.filter(item => item.id === i)[0])
                        
                           voice.renderTree(ctx)
                       }

                   }       
               })
           }
           
        }
      }

    render() { 
        // const { controlsOpen, voices } = this.props

        return ( 
            <div>
                <Canvas  className="tree-canvas" draw={this.draw} width={canvasWidth} height={canvasHeight}/>
                {/* {
                    controlsOpen && 
                        <ControlsModal controlsOpen={controlsOpen} voices={voices}/>
                    } */}
            </div>
         );
    }
}
 
const mapStateToProps = state => ({
    loaded : state.granular.loaded,
    playingSoundsList : state.soundsInfo.playingSoundsList,
    // controlsOpen : state.controls.controlsOpen,
    voices : state.granular.voices,
    // soundCircles : state.soundCircles,
    
    // timerStarted : state.granular.timerStarted,
    // controlsOpen : state.controls.controlsOpen,
    
    // pausedVoices : state.controls.pausedVoices,
    // soundsInfo : state.soundsInfo.soundsInfoArray,

    // soundFileData : state.granular.soundFileDatas,
    // voiceSettings : state.controls.voiceSettings,
    // masterSettings : state.controls.masterSettings,
  }) 

export default connect(mapStateToProps)(TreeFarming);