import React from 'react'
import { useDispatch } from 'react-redux'
import {  NavLink } from 'react-router-dom'
import { toggleControlsOpen } from '../../redux/controls/controls.actions'
// import { toggleMoreAbout } from '../../redux/more-about/more-about.actions'

import Logo from '../Logo/Logo'
import ControlsIcon from '../controls-icon/ControlsIcon'
import './header.styles.css'

const Header = ({ started, uploadPageOpen }) => {

const dispatch = useDispatch();




// useEffect(() => {
//     dispatch(toggleControlsOpen)
// })


    return (
        <div className="header">
            <div className="flex">
               
                <NavLink to="/" className="header-title"> 
                    {/* <img src="/logo.jpg" alt="silence farm logo" className="logo"/> */}
                    <Logo className="logo"/>
                </NavLink>
                {/* <div className="divider"> | </div> */}
            </div>

                {
                    !uploadPageOpen &&

                        
         

            <div className="flex">
                {/* <NavLink to="/controls" className="header-link">controls</NavLink> */}
                
                {/* <div className="divider"> | </div> */}

  

                <NavLink to="/upload" className="header-link" >
                    <p className="white">upload</p>
                </NavLink>
                    
                                  {
                                        started ? 
                                            <div  className="header-controls" onClick={() => dispatch(toggleControlsOpen())}>
                                                <ControlsIcon />
                                            </div>
                                            :
                                            null
                                      
                    
                                 }

                    
           <NavLink to="/about"  className="header-link" >
                        <p className="white"> ? </p>
                    </NavLink>
            </div>
            

                }

                

        </div>
    )



}
export default Header