import React, { Component } from 'react'
import mime from 'mime-types'
import { v4 as uuidv4 } from 'uuid'
import { initBufferFromFile, checkSilence  } from '../../redux/audio-middleware/audio.utils'
import ChooseSound from '../../components/ChooseSound/ChooseSound'
import RecordSound from '../../components/RecordSound/RecordSound'
import DropZone from '../../components/drop-zone/DropZone'
import './UploadFileModal.css'

class FileModal extends Component {
    state = { 
        file: null,
        authorized: ['audio/mpeg'],
        fileChecksOut : false,
     }

     clearFile = () => this.setState({ file: null});

     isAuthorized = filename => {
         const { authorized } = this.state;
        
        return authorized.includes(mime.lookup(filename))
     }


     checkFile = (file) => {
         const { audioContext } = this.props;
        

        const reader = new FileReader()

        reader.addEventListener("load", () =>  {
          // convert image file to base64 string
          const data = reader.result;
          
          initBufferFromFile (audioContext, data).then((buf) => {
            
              const waveformData = buf.getChannelData(0)
              
                if(checkSilence(waveformData)){
                 
                    this.setState({fileChecksOut : true}, () => {
                        this.sendFile(file, waveformData);
                    })
                } else {
                    this.setState({fileChecksOut: false}, () => {
                        this.rejectFile();
                    })
                  
                }
  
              })
              .catch((err) => {
                console.error(err);
            })
  
        }, false);
      
        if (file) {
          reader.readAsArrayBuffer(file);
        }        
     }

     rejectFile = () => {
        const { uploadFile, closeModal } = this.props
        
         closeModal()
         this.clearFile()
         uploadFile(null)
         //need to set up a rejected message below
     }


//refactor this so the component returns a file and I can get rid of addRecording
     addFile = event => {
         event.preventDefault()
         const file = event.target.files[0]
         const fileSize = file.size / 1024 / 1024
       
         if(file){
             if(fileSize > 1){
                 alert('file cannot be bigger than 1MB')
             } else {
                 this.setState({ file }, () => {
                    this.checkFile(file);
                 })
                 
             }
         }
     }

     addDroppedFile = (e) => {
        e.preventDefault()
        const file =  e.dataTransfer.files[0];
        const fileSize = file.size / 1024 / 1024;
        if(file){
            if(fileSize > 1){
                alert('file cannot be bigger than 1MB')
            } else {
                this.setState({ file }, () => {
                    this.checkFile(file);
                })
                
            }
        }
     }

     convertBlobToFile = (blob) => {
        blob.lastModifiedDate = new Date();
        blob.name = `${uuidv4()}.mp3`
        return blob
    }


     addRecording = blob => {
        const file = this.convertBlobToFile(blob)
      


        const fileSize = file.size / 1024 / 1024
      
         if(file){
             if(fileSize > 1){
                alert('file cannot be bigger than 1MB')
            } else {
                this.setState({ file }, ()=> {
                    this.checkFile(file)
                })
                
            }
         }
     }

     sendFile = (file, waveformData) => {
        //  const { file } = this.state
         const { uploadFile, closeModal, upload } = this.props
        
         if(file !== null){
           
             if(this.isAuthorized(file.name)){
                const metadata = { contentType: mime.lookup(file.name)}
               
                uploadFile(file, metadata, waveformData, !upload)
                closeModal()
                this.clearFile()
                // this.setState({ file: null})
                
             }
         } else {
             alert('please check that the file is less than 1MB and silence farm-able ;) ')
         }
     }


    render() { 
        // const { file, fileChecksOut } = this.state;
        const { file} = this.state;
        const { modal, upload } = this.props;
        return ( 
            <div >
                {
                    modal ? 
                    <div className="modal-container">
                    
                        <div className="file-input-container">
                        {/* <h3 className="select-file-header">{file? 'selected sound file: ' : 'select a sound file: '}</h3> */}
                            {
                                !file &&
                                <div className="get-file-container">
                                    
                                    {
                                        upload ?
                                            <DropZone updateParent={this.addDroppedFile}>                     
                                                <ChooseSound updateParent={this.addFile}/>
                                            </DropZone>
                                            

                                            :

                                            <RecordSound updateParent={this.addRecording}/>
                                            
                                    }


                                </div>
    
                            }
                       <div className="buttons-container">

                            {/* {   file ? 
                                <div>
                                    {
                                    fileChecksOut ? 
                                            <button className="upload-file-button big-button" onClick={this.sendFile}>
                                                upload file
                                            </button>
                                            :

                                            <button className="check-file-button big-button" onClick={this.checkFile}>
                                                check file
                                            </button>
                                        }
                                </div>
                                :
                                null
                            } */}


                        </div>


                            {/* <div className="file-name-container">
                            <p className="upload-file-text">file should be({`< 1`} MB)</p>w
                                {
                                file ? 

                                    <p className="file-name-black-text">{file.name}</p>
                                    :
                                    <p className="file-name-red-text">no file</p>
                                }
                            </div>     */}
                        </div>

                        


 
                    </div>
                :
                    <div></div>
                }
            </div>
           
 

         )
    }
}
 
export default FileModal;