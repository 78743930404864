import React from 'react'
import { Link } from 'react-router-dom'
import './UploadSuccess.css'

const UploadSucess = () => {
    return ( 

        <div className="successs-container">
            <div className="upload-banner">
                <p className="thank-you-message">thank you for your contribution!</p>
                <p className="thank-you-message">we'll add it to the compost.</p>
                <Link to="/" className="big-button success-button">back to the farm</Link>
            </div>
        </div>
     );
}
 
export default UploadSucess;