import React from 'react'
import './StartButton.css'

const StartButton = ({start}) => {
    return ( 
        <div>
            <button className="farm-silence-button huge-button" onClick={start}>farm silence</button>
        </div>
        
     );
}
 
export default StartButton;


