// import React, { useState } from 'react'
import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { useSelector,  useDispatch } from 'react-redux'
// import { toggleUploadPageOpen } from '../../redux/upload/upload.actions'
import UploadLogin from '../upload-login/UploadLogin'
import UploadLanding from '../upload-choose-method/UploadLanding'
import UploadSound from '../upload-sound/UploadSound'
// import RecordSound from '../upload-record-file/RecordSound'
import './UploadPage.css'



class Upload extends Component{
    state = {
        uploading: false,
        upload: false,
    }

    // const dispatch = useDispatch();
    // const [ uploading, setUploading] = useState(null)
    // const [email, toggleEmail ] = useState(false);
    // const currentUser = useSelector(state => state.user.currentUser);


    // useEffect(() => {
    //     dispatch(toggleUploadPageOpen())
    //     return function cleanup(){
    //         dispatch(toggleUploadPageOpen())
    //     }
    // },[dispatch])

   
    setUploading = (uploading, upload) => {
        this.setState({ uploading, upload})
    }

    render(){
        const { currentUser } = this.props
        const { uploading, upload } = this.state
        return (
            <div className="upload-bg">
                {
                    !currentUser ? 
                        <UploadLogin />
                        :
                        !uploading ?
                            <UploadLanding currentUser={currentUser} updateParent={this.setUploading}/>
                            :
                            <UploadSound currentUser={currentUser} updateParent={this.setUploading} upload={upload}/>            
                    }
               
                
               
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentUser : state.user.currentUser,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Upload)