import React, { Component } from 'react';
import Canvas from '../canvas-use/Canvas'

class VolumeMeter extends Component {
    state = {  }



    draw(){
        // const { width, height, maxVolume } = this.props
        // const canvasCtx = this.refs.canvas.getContext('2d')
        // let prevVolume = 0
    
        // const drawLoop = () => {
        //   if (this.analyser.ended) return
        //   const volume = this.getVolume()
        //   draw(width, height, canvasCtx, volume, prevVolume, maxVolume || 50)
        //   prevVolume = volume
        //   this.rafId = window.requestAnimationFrame(drawLoop)
        // }
    
        // drawLoop()
    }

    render() { 
        const { meterWidth, meterHeight } = this.props
        return (  
            <div>
                 <Canvas draw={this.draw} width={meterWidth} height={meterHeight} className="meter-canvas"/>
            </div>
        );
    }
}
 
export default VolumeMeter;