import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateVoiceAudioSettings, updatePaused } from '../../redux/controls/controls.actions'
import ItemSlider from '../item-slider/ItemSlider'
import PauseButton from '../pause-button/PauseButton'
import './ItemControls.css'

        // volume : 0.0,
        // rate: 0.0,
        // attack : 0.0,
        // duration : 0.0,
        // release : 0.0,
        // density : 0.0,

const config = {
    items : [
        {
            name: 'volume',
            minVal: 0.01,
            maxVal: 1.0,
            step: 0.01,
        },
        {
            name: 'rate',
            minVal: 0.1,
            maxVal: 3.0,
            step: 0.01,
        },
        {
            name: 'attack',
            minVal: 0.01,
            maxVal: 3.0,
            step: 0.01,
        },
        {
            name: 'duration',
            minVal: 0.01,
            maxVal: 5.0,
            step: 0.01,
        },
        {
            name: 'release',
            minVal: 0.01,
            maxVal: 3.0,
            step: 0.01,
        },
        {
            name: 'density',
            minVal: 1,
            maxVal: 100,
            step: 1,
        }
        
    ]
}


class ItemControls extends Component {

    state = {
        paused: true,
        // volume : 0.0,
        // rate: 0.0,
        // attack : 0.0,
        // duration : 0.0,
        // release : 0.0,
        // density : 0.0,
    }

    componentDidMount(){
        const { voiceSettings, pausedVoices, id  } = this.props;
        console.log(voiceSettings);
        this.setState({ settings: {...voiceSettings}, paused: pausedVoices[id], id})
    }


    update = (prop, value) => {
        const { settings } = this.state;
        const { updateVoiceAudioSettings } = this.props;
        const newSettings = {...settings, [prop] : Number(value)};
        this.setState({ settings: newSettings });
        console.log(settings.id, newSettings);
        updateVoiceAudioSettings(settings.id, newSettings);
    }

    togglePaused = () => {
        const { id, paused } = this.state
        const { updatePaused } = this.props
        this.setState({paused : !paused})
        console.log(id)
        updatePaused(id)

    }



    render(){
        // const {volume, rate, attack, duration, release} = this.props.item.settings;
        const {settings, paused} = this.state;
       
        // const { id } = this.props;
       if(settings){
        console.log(settings.id);
        return ( 
        
            <div className={`item-controls ${paused ? 'paused' : ''}`}>
    
                <h6 className="voice-title"> voice {settings.id}</h6>
                {
                paused === undefined ?
                    
                    null
                    
                :
                    <PauseButton togglePaused={this.togglePaused} id={settings.id} paused={paused}/>
                    
                }
                <React.Fragment>
                    {
                        settings && 
                        config.items.map(item => {
                            return(
    
                                <div className="item-slider-item" key={`item-control-${settings.id}-${item.name}`}>
                                    {
                                    item.name === 'density' ? 
                                        <ItemSlider 
                                        name={item.name} 
                                        value={settings[item.name]} 
                                        updateParent={this.update}  
                                        minVal={item.minval} 
                                        maxVal={item.maxVal}
                                        step={item.step}
                                        paused={paused}
                                        />
                                        
                                    :
    
                                        <ItemSlider 
                                        name={item.name} 
                                        value={parseFloat(settings[item.name]).toFixed(2)} 
                                        updateParent={this.update}  
                                        minVal={item.minval} 
                                        maxVal={item.maxVal}
                                        step={item.step}
                                        paused={paused}
                                            
                                        />
                                    }
                                    </div>
                            )
                                
                            
                        })
                    }
        
    
    
    
                </React.Fragment>
    
                
                
            </div>
         )
       } else {
           return (
               <div></div>
           )
       }
    
    }
}
 
const mapStateToProps = state => ({
    pausedVoices : state.controls.pausedVoices,
})

const mapDispatchToProps = dispatch => ({
    updateVoiceAudioSettings : (id, settings) => dispatch(updateVoiceAudioSettings(id, settings)),
    updatePaused : (idx) => dispatch(updatePaused(idx)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ItemControls);