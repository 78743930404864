import React from 'react'

const ChooseSound = ({updateParent}) => {
    return ( 

        <label className="choose-file-button">
        <input  onChange={updateParent}
            className="file-input"
            name="file"
            type="file" 
            />
        choose file
        </label>

     );
}
 
export default ChooseSound;