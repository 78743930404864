
import { applyMiddleware, createStore } from 'redux'
import { combineReducers } from 'redux'
import audioMiddleWare from './audio-middleware/audio.middleware'
import masterClockMiddleware from './masterClock/masterClock.middleware'
import { granularReducer } from './granular/granular.reducer'
import { mouseReducer } from './mouse/mouse.reducer'
import { controlsReducer } from './controls/controls.reducer'
import { soundsInfoReducer } from './sounds-info/soundsInfo.reducer'
import { userReducer } from './user/user.reducer'
import { uploadReducer } from './upload/upload.reducer'
import { presetsReducer } from './presets/presets.reducer'

const rootReducer = combineReducers({
  mouse : mouseReducer,
  soundsInfo : soundsInfoReducer,
  granular : granularReducer,
  controls : controlsReducer,
  presets: presetsReducer,
  user : userReducer,
  upload: uploadReducer,
})


const store = createStore(rootReducer, applyMiddleware(audioMiddleWare, masterClockMiddleware));

export default store