import React, { Component } from 'react'
import MicRecorder from 'mic-recorder-to-mp3'
import VolumeMeter from '../volume-meter/VolumeMeter'
import './RecordSound.css'

const recorder = new MicRecorder({ bitRate : 128 })
class RecordSound extends Component {
  constructor(props){
    super(props)
    this.state = { 
      isRecording : false,
      file: null,
      isBlocked: false,
   }
  }
    

     componentDidMount() {
     
      console.log(recorder);
        navigator.getUserMedia({ audio: true },
          () => {
            console.log('Permission Granted');
            console.log(recorder);
            this.setState({ isBlocked: false, recorder });
          },
          () => {
            alert('PERMISSION DENIED: this needs access to your microphone');
            this.setState({ isBlocked: true })
          },
        );
      }

      start = () => {
        
        if (this.state.isBlocked) {
          console.log('Permission Denied');
        } else {
          recorder
            .start()
            .then(() => {
              this.setState({ isRecording: true});
              console.log(recorder);
            }).catch((e) => console.error(e));
        }
      };
    
      stop = () => {
        const { updateParent } = this.props
        console.log(recorder);
        recorder
          .stop()
          .getMp3()
          .then(([buffer, blob]) => {
            
            const file = blob
            const isRecording = false;
            this.setState({ file, isRecording });
            updateParent(file, isRecording)
          }).catch((e) => console.log(e));
      };


    render() { 
      const { isRecording } = this.state
        return ( 

          <div>
            <div className="recording-intro-text">
              <p>after you click the record button, we'll try to access your microphone.</p>
              <p>try to record about 20 seconds or so, then hit the stop button.</p>
              {/* <p>(one of these days I intend to add metering...)</p> */}
              {/* <p>if that succeeds, the recording should automagically end and upload after 20 seconds.</p> */}
            </div>
          
            <div className="meter">
                <VolumeMeter /> 
            </div>



            <div className="buttons-container">
   



            { 
                    isRecording ? 
                            <button className="stop-button big-button" onClick={this.stop}>
                                stop
                            </button>
                            :

                            <button className="record-button big-button" onClick={this.start}>
                                record
                            </button>
            }
    


        </div>
      </div>
         );
    }
}
 
export default RecordSound;