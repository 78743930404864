import React from 'react';
import './ItemSlider.css';

const ItemSlider = ({name, updateParent, value, minVal, maxVal, step, paused}) => {
    return (
        <div className="item-slider-container">
        <label htmlFor={name} className="item-slider-label">
            {name} <span className="red">({ value })</span>
        </label>
        <input
            className="item-slider-thing"
            name={name}
            type="range"
            min={minVal}
            max={maxVal}
            step={step}
            value={value}
            onChange={(e) => updateParent(name, e.target.value)}
        />
    </div>
    )
}
export default ItemSlider