import React, { Component } from 'react';
import { connect } from 'react-redux'
import NamesList from '../../components/NamesList/NamesList'
// import ControlsModal from '../../components/controls-modal/ControlsModal'

class TextFarming extends Component {
    state = {  }
    render() { 
        // const { controlsOpen, playingSoundsList, voices } = this.props
        const { playingSoundsList } = this.props

        return ( 
            <div>
                
                    <NamesList playingSoundsList={playingSoundsList}/>

              
            </div>
         );
    }
}
const mapStateToProps = state => ({
    playingSoundsList : state.soundsInfo.playingSoundsList,
    controlsOpen : state.controls.controlsOpen,
    voices : state.granular.voices,
    // soundCircles : state.soundCircles,
    // loaded : state.granular.loaded,
    // timerStarted : state.granular.timerStarted,
    // controlsOpen : state.controls.controlsOpen,
    
    // pausedVoices : state.controls.pausedVoices,
    // soundsInfo : state.soundsInfo.soundsInfoArray,
    // soundFileData : state.granular.soundFileDatas,
    // voiceSettings : state.controls.voiceSettings,
    // masterSettings : state.controls.masterSettings,
  }) 


export default connect(mapStateToProps, null)(TextFarming);