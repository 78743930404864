import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

import { firebaseConfig } from './firebaseConfig';
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();

export default firebase;









// class Firebase {
//     constructor(){
//         firebase.initializeApp(firebaseConfig);
//         this.auth = firebase.auth();
//     }

//     // async register(name, email, password){
//     //     await this.auth.createuserWithEmailAnddPassword(email, password);
//     //     await newUser.user.updateProfile({
//     //         displayName: name
//     //     })
//     // }

//     // async login(email, password){
//     //     return await this.auth.signInWithEmailAndPassword(email, password);
//     // }

// }

// const myFirebase = new Firebase();



// export default myFirebase