import {UserActionTypes  } from './user.action.types'
// {name: 'sam', location: 'kibesillah, ca'}
const INITIAL_STATE = {
    // currentUser : {name: 'sam', location: 'kibesillah, ca'},
    currentUser : null,
}

export const userReducer = (state=INITIAL_STATE, action) => {
    switch(action.type){
        case  UserActionTypes.SET_CURRENT_USER : 
        console.log('in reducer')
            return {
                ...state,
                currentUser: action.payload.userData,
            }
        default :
        return {
            ...state
        }    
    }
}