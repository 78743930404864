import React from 'react'
import './PresetSelectionBar.css'

const selectedColors = ['decay-color', 'percussive-color', 'mellow-color', 'lively-color'];


const PresetSelectionBar = ({presets, currentPresetIdx, updateParent}) => {
    return ( 
        <div className="preset-controls-container">
            
  
        {
            presets.map((theme, i) => 
                <button 
                    key={`preset-toggle-${presets[i].name}`} 
                    className={`preset-button ${i === currentPresetIdx ? selectedColors[i] : 'deselected-color'}`} 
                    onClick={() => updateParent(i)}
                >
                {theme.name}
                </button>
                )
        }
        </div>
     );
}
 
export default PresetSelectionBar;