import React from 'react'
import './Play-Icon.css'

const PlayIcon = () => {
    return ( 
        <svg viewBox="0 0 40 40" className="play-icon">
            <polygon 
                className="play-triangle" 
                points={`${10}, ${10}, ${30}, ${20}, ${10}, ${30}`}
                // points={`${x + size/4}, ${y - size/2},${x - size/2},${y},${x + size/4},${y + size/2}`} 
                // points={`${x + size/4}, ${y - size/2},${x - size/2},${y},${x + size/4},${y + size/2}`} 
                fill="#000000"
                />
        </svg>
     );
}
 
export default PlayIcon;