import firebase from './firebase'
const firestore = firebase.firestore();

export const auth = firebase.auth();

export const createUserProfileDocument = async (userAuth, additionalData) => {
    if(!userAuth) return;
    const userRef = firestore.doc(`users/${userAuth.uid}`);
    const snapShot = await userRef.get();
    // console.log(userAuth, 'in firebase');
    if(!snapShot.exists){
        const { username, email} = userAuth;
        const createdAt = new Date();
        try {
            await userRef.set({
                username,
                email,
                createdAt,
                ...additionalData
            })
        } catch(error) {
            console.log(error.message);
        }
    }
    return userRef
}


export const addItemToFirestore = async(collectionKey, objectToAdd) => {
    const collectionRef = firestore.collection(collectionKey);
    console.log(objectToAdd, 'in firestore');
    console.log("collectionref:", collectionRef);
    
    return await collectionRef.add(objectToAdd)
}



