import React from 'react'
import { Link } from 'react-router-dom'
import './UploadLanding.css'


const UploadLanding = ({currentUser, updateParent}) => {
   console.log(currentUser);
    return ( 
       <div className="wrapper">
 <div className="upload-landing-container">
           <div className="welcome-user">
           {/* <p className="upload-landing-name">Hi, { currentUser.name}!</p>  */}
           <p> </p>
           </div>
            <div className="explain-farming">
               {/* <p className="upload-landing-text">thanks for helping out!</p> */}
                  <p className="upload-landing-text">the farm can use short recordings in an mp3 format of your specific silence -- 
                     you can upload one that you recorded on your own or record one through the browser. </p>
               
            </div>
            <div className="welcome-user">
           {/* <p className="upload-landing-name">Hi, { currentUser.name}!</p>  */}
           
           </div>
            {/* <p className="upload-landing-text">Your silent contribution should be a short (20 seconds?) mp3 file.</p> */}
            {/* <p className="upload-landing-text">After you upload it, it will be analyzed, and if it has too much dynamic range or is too loud in general, or 
               isn't a short mp3 file, you'll have to redo the process.</p> */}
               {/* <p className="upload-landing-text">You can upload a pre-recorded file from your computer or record a new one in your browser.</p>     */}

            <div className="button-container">
            <button className="upload big-button" onClick={() => updateParent(true, true)}>
                  upload   
               </button>      

               <button className="record big-button" onClick={() => updateParent(true, false)}>
                  record
               </button> 

               
            </div>
            <Link to="/" className="big-button success-button">back to the farm</Link>
        </div>
       </div>
       
     );
}
 
export default UploadLanding;