
const presets = {
    percussive : {
        minVol: 0.5,
        maxVol: 0.8,
        minRate: 1.5,
        maxRate: 2.0,
        minAtk: 0.01,
        maxAtk: 0.05,
        minRel: 0.3,
        maxRel: 1.0,
        minDur: 0.1,
        maxDur: 0.1,
        density : 10,
        baseMutationValue: 5,
        numVoices: 6,
    },  
    decayDrone: {
        minVol: 0.2,
        maxVol: 0.4,
        minRate: 0.1,
        maxRate: 0.3,
        minAtk: 1.0,
        maxAtk: 3.0,
        minRel: 1.0,
        maxRel: 3.0,
        minDur: 1.0,
        maxDur: 3.0,
        density : 80,
        baseMutationValue: 1,
        numVoices: 6,
    },
    mellow : {
        minVol: 0.2,
        maxVol: 0.4,
        minRate: 0.2,
        maxRate: 0.8,
        minAtk: 0.5,
        maxAtk: 1.0,
        minRel: 0.5,
        maxRel: 1.0,
        minDur: 0.1,
        maxDur: 1.0,
        density : 80,
        baseMutationValue: 1,
        numVoices: 6,
    },
    lively : {
        minVol: 0.2,
        maxVol: 0.4,
        minRate: 0.1,
        maxRate: 0.4,
        minAtk: 0.1,
        maxAtk: 1.0,
        minRel: 0.1,
        maxRel: 1.0,
        minDur: 1.0,
        maxDur: 5.0,
        density : 10,
        baseMutationValue: 30,
        numVoices: 6,
    }

}

export default presets